<template>
	<div id="model">
		<h1>{{ title }}</h1>

		<router-view v-slot="{ Component }">
			<transition name="page" mode="out-in">
				<component :is="Component" />
			</transition>
		</router-view>
	</div>
</template>

<script>
import { meta } from "../configuration/config-public-merged.js";

export default {
	name: "App",

	computed: {
		title () {
			const route = this.$route.name;

			if (!route)
				return "";

			return meta[`${route.toLowerCase()}-title`] || route;
		}
	}
};
</script>

<style scoped>
h1 {
	text-align: center;
}
</style>

<style>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #2c3e50;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-height: 100vh;
	background-color: #f0f2ef;
}

body {
	margin: 0;
}

#model {
	background-color: white;
	padding: 40px 80px;
	border-radius: 10px;
	width: 400px;
	margin: 20px 0;
}

/* Fade Transitions */
.fade-enter-active, .fade-leave-active,
.fadeInput-enter-active, .fadeInput-leave-active,
.fadeInput-enter-active > *, .fadeInput-leave-active > * {
	transition: all .3s ease-out !important;
}

.fade-enter-from, .fade-leave-to {
	height: 0px;
	line-height: 0px;
}

.fade-enter-from, .fade-leave-to,
.fadeInput-enter-from, .fadeInput-leave-to,
.fadeInput-enter-from > *, .fadeInput-leave-to > * {
	opacity: 0 !important;
	margin: 0px  !important;
	padding: 0px  !important;
	border-width: 0px  !important;
	font-size: 0px !important;
}

/* Page Transition */
.page-enter-active, .page-leave-active {
	transition: opacity .15s;
}

.page-enter-from, .page-leave-to {
	opacity: 0 !important;
}

</style>
