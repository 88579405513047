import matrix from "@/matrix";
import store from "@/store";

const getUID = () => localStorage.getItem("interaction");

/**
 * This handles all the communication between the front end and the back end.
 */
export default {
	/**
	 * Log a user into matrix.
	 *
	 * @param {string} user The matrix id.
	 * @param {string} password The matrix password.
	 * @param {string} homeserver The matrix homeserver.
	 *
	 * @async
	 * @return {string} The access token.
	 * @throws {Error} An error if login failed.
	 */
	matrixLogin: async (user, password, homeserver) => {
		const login = () => matrix.client.login("m.login.password", {
			user,
			password,
			initial_device_display_name: "SSO"
		});

		try {
			matrix.client = matrixcs.createClient(homeserver);

			return await login();
		} catch (e) {
			if (e.errcode)
				throw e;

			const discovery = await matrix.AutoDiscovery.findClientConfig(
				homeserver.replace("https://", "").replace("http://", "")
			);

			if (discovery["m.homeserver"].state !== "SUCCESS")
				throw { message: "Failed to query server information." };

			matrix.client = matrixcs.createClient(discovery["m.homeserver"].base_url);

			return await login();
		}
	},

	/**
	 * Get the logged in user's matrix Open ID token.
	 * This is just a convinient alias for matirx.client.getOpenIdToken
	 *
	 * @async
	 * @return {string} The access token.
	 * @throws {Error} An error if obtaining the token failed.
	 */
	getOpenIdToken: async () => {
		return (await matrix.client.getOpenIdToken()).access_token;
	},

	/**
	 * Log into the OpenID connect system.
	 *
	 * @param {string} user The matrix id.
	 * @param {string} token The matrix Open ID token.
	 *
	 * @async
	 * @return {string} The redirection url.
	 * @throws {Error} An error if the login failed.
	 */
	login: async (user, token) => {
		const rawRes = await fetch(`/interaction/${getUID()}/login`, {
			method: "POST",

			body: JSON.stringify({
				id: user,
				token
			}),

			headers: {
				"Content-Type": "application/json",
				"CSRF-Token": store.state.csrf
			}
		});

		const res = await rawRes.json();

		if (!res.redirectTo)
			throw res;

		return res.redirectTo;
	},

	/**
	 * Update the store with the new session from the redirect url.
	 *
	 * @param {string} url The redirect url that the Open ID Connect system passes.
	 *
	 * @async
	 * @return {undefined}
	 * @throws {Error} An error if session update failed.
	 */
	updateRedirectedSession: async url => {
		const rawRedirect = await fetch(url);

		localStorage.setItem("interaction", rawRedirect.url.split("/interaction/")[1]);
	},

	/**
	 * Log the user out.
	 *
	 * @async
	 * @return {undefined}
	 * @throws {Error} An error if logour failed.
	 */
	logout: async () => {
		const rawRes = await fetch("/session/end/confirm", {
			method: "POST",

			headers: {
				"Content-Type": "application/json",
				"CSRF-Token": store.state.csrf
			}
		});

		if (!rawRes.ok)
			throw await rawRes.json();
	},

	/**
	 * Consent to the interaction.
	 *
	 * @async
	 * @return {string} The redirection url.
	 * @throws {Error} An error if consent failed.
	 */
	consent: async () => {
		const rawRes = await fetch(`/interaction/${getUID()}/confirm`, {
			method: "POST",

			headers: {
				"Content-Type": "application/json",
				"CSRF-Token": store.state.csrf
			}
		});

		const res = await rawRes.json();

		if (!res.redirectTo)
			throw res;

		return res.redirectTo;
	},

	/**
	 * Update the user's email.
	 *
	 * @param email The email to update the user with.
	 *
	 * @async
	 * @return {undefined}
	 * @throws {Error} An error if updating of the email failed.
	 */
	update: async email => {
		const rawRes = await fetch(`/interaction/${getUID()}/update`, {
			method: "POST",

			body: JSON.stringify({
				email
			}),

			headers: {
				"Content-Type": "application/json",
				"CSRF-Token": store.state.csrf
			}
		});

		if (!rawRes.ok)
			throw await rawRes.json();
	},

	/**
	 * Get the current interaction state.
	 *
	 * @async
	 * @return {object} The state object.
	 * @throws {Error} An error if obtaining the state faild
	 */
	getState: async () => {
		// No iteraction - everything can just stay null
		if (!getUID())
			return;

		const rawRes = await fetch(`/interaction/${getUID()}/state`, {
			method: "POST",

			headers: {
				"CSRF-Token": store.state.csrf
			}
		});

		return await rawRes.json();
	},

	/**
	 * Get all the groups.
	 *
	 * @async
	 * @return {Array.object} The array of groups.
	 * @throws {Error} An error if getting the groups failed.
	 */
	getGroups: async () => {
		const res = await fetch("/access/groups", {
			method: "POST",

			headers: {
				"CSRF-Token": store.state.csrf
			}
		});

		const json = await res.json();

		if (!res.ok)
			throw json;

		return json;
	},

	/**
	 * Add a user to a group.
	 *
	 * @param {string} matrix The matrix ID of the user to add to the group.
	 * @param {string} group The name of the group to add the user to.
	 *
	 * @async
	 * @return {undefined}
	 * @throws {Error} An error if adding the user to the group failed.
	 */
	setUsersGroup: async (matrix, group) => {
		const res = await fetch(`/access/groups/${group}`, {
			method: "POST",

			body: JSON.stringify({ matrix }),

			headers: {
				"Content-Type": "application/json",
				"CSRF-Token": store.state.csrf
			}
		});

		if (!res.ok)
			throw await res.json();
	},

	/**
	 * Update or create a group.
	 *
	 * @param {string} name The name of the group.
	 * @param {object} rules The rules for this group.
	 *
	 * @async
	 * @return {undefined}
	 * @throws {Error} An error if setting the group failed.
	 */
	setGroup: async (name, rules) => {
		const res = await fetch(`/access/groups/${name}`, {
			method: "POST",

			body: JSON.stringify({ rules }),

			headers: {
				"Content-Type": "application/json",
				"CSRF-Token": store.state.csrf
			}
		});

		if (!res.ok)
			throw await res.json();
	},

	/**
	 * Delete a group.
	 *
	 * @param {string} name The name of the group.
	 *
	 * @async
	 * @return {undefined}
	 * @throws {Error} An error if deleting the group failed.
	 */
	deleteGroup: async name => {
		const res = await fetch(`/access/groups/${name}`, {
			method: "DELETE",

			headers: {
				"CSRF-Token": store.state.csrf
			}
		});

		if (!res.ok)
			throw await res.json();
	},

	/**
	 * Get all the clients.
	 *
	 * @async
	 * @return {Array.object} The array of clients.
	 * @throws {Error} An error if getting the clients failed.
	 */
	getClients: async () => {
		const res = await fetch("/access/clients", {
			method: "POST",

			headers: {
				"CSRF-Token": store.state.csrf
			}
		});

		const json = await res.json();

		if (!res.ok)
			throw json;

		return json;
	},

	/**
	 * Update or create a client.
	 *
	 * @param {object} config The client configuration.
	 * @param {string} config.id The client ID.
	 * @param {string} config.secret The client secret.
	 * @param {string} config.method The token endpoint authentication method.
	 * @param {Array.string} config.redirectUris The clients redirection uris.
	 * @param {Array.string} config.grantTypes The grant types for this client.
	 * @param {Array.string} config.responseTypes The response types for this
	 * client.
	 *
	 * @async
	 * @return {undefined}
	 * @throws {Error} An error if setting the clinet failed.
	 */
	setClient: async config => {
		const res = await fetch(`/access/clients/${config.id}`, {
			method: "POST",

			body: JSON.stringify(config),

			headers: {
				"Content-Type": "application/json",
				"CSRF-Token": store.state.csrf
			}
		});

		if (!res.ok)
			throw await res.json();
	},

	/**
	 * Delete a clinet.
	 *
	 * @param {string} id The client ID to delete.
	 *
	 * @async
	 * @return {undefined}
	 * @throws {Error} An error if deleting the client failed.
	 */
	deleteClient: async id => {
		const res = await fetch(`/access/clients/${id}`, {
			method: "DELETE",

			headers: {
				"CSRF-Token": store.state.csrf
			}
		});

		if (!res.ok)
			throw await res.json();
	},

	/**
	 * Get a list of users and their configuration.
	 *
	 * @async
	 * @return {Array.object} The list of users.
	 * @throws {Error} An error if getting the users failed.
	 */
	getUsers: async () => {
		const res = await fetch("/access/users", {
			method: "POST",

			headers: {
				"CSRF-Token": store.state.csrf
			}
		});

		const json = await res.json();

		if (!res.ok)
			throw json;

		return json;
	},

	/**
	 * Set the users' configuration.
	 *
	 * @param {Array.object} users The list of user objects to update to.
	 *
	 * @async
	 * @return {undefined}
	 * @throws {Error} An error if setting the users failed.
	 */
	setUsers: async users => {
		const res = await fetch(`/access/users`, {
			method: "POST",

			body: JSON.stringify({users}),

			headers: {
				"Content-Type": "application/json",
				"CSRF-Token": store.state.csrf
			}
		});

		if (!res.ok)
			throw await res.json();
	},

	/**
	 * Get a list of all the service urls defined in the clients.
	 *
	 * @async
	 * @return {Array.string} The list of urls.
	 * @throws {Error} An error if getting the domains fails.
	 */
	getDomains: async () => {
		const res = await fetch("/access/domains", {
			method: "POST",

			headers: {
				"CSRF-Token": store.state.csrf
			}
		});

		const json = await res.json();

		if (!res.ok)
			throw json;

		return json;
	}
};
