/**
 * Handle merging the defaults with set configuration for the front end.
 * This is done in seperate files to not expose backend configuration to the
 * front end.
 */

const merge = require("./merge");
const defaults = require("./config-public.defaults.json");

let result = defaults;

// Load config file if it is there.
try {
	const local = require("./config-public.json");

	result = merge(defaults, local);
} catch (e) { }

module.exports = result;
