import { createRouter, createWebHistory } from "vue-router";
import { nextTick } from "vue";
import { meta } from "../../configuration/config-public-merged.js";
import store from "@/store";
import Home from "../views/Home.vue";

// login guard.
const requiresLogin = (to, from, next) => {
	if (store.state.loggedIn)
		return next();

	return next({name: "Login"});
};

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home
	},
	{
		path: "/admin/groups",
		name: "Groups",
		component: () => import(/* webpackChunkName: "groups" */ "../views/Groups.vue")
	},
	{
		path: "/admin/groups/edit/:name?",
		name: "Group",
		props: true,
		component: () => import(/* webpackChunkName: "group" */ "../views/Group.vue")
	},
	{
		path: "/admin/clients",
		name: "Clients",
		component: () => import(/* webpackChunkName: "clients" */ "../views/Clients.vue")
	},
	{
		path: "/admin/clients/edit/:id?",
		name: "Client",
		props: true,
		component: () => import(/* webpackChunkName: "client" */ "../views/Client.vue")
	},
	{
		path: "/admin/users",
		name: "Users",
		component: () => import(/* webpackChunkName: "users" */ "../views/Users.vue")
	},
	{
		path: "/admin",
		name: "Admin",
		component: () => import(/* webpackChunkName: "admin" */ "../views/Admin.vue")
	},
	{
		path: "/consent",
		name: "Consent",
		component: () => import(/* webpackChunkName: "consent" */ "../views/Consent.vue"),
		beforeEnter: requiresLogin
	},
	{
		path: "/error",
		name: "Error",
		component: () => import(/* webpackChunkName: "error" */ "../views/Error.vue")
	},
	{
		path: "/noaccess",
		name: "No Access",
		component: () => import(/* webpackChunkName: "noaccess" */ "../views/NoAccess.vue")
	},
	{
		path: "/update",
		name: "Update",
		component: () => import(/* webpackChunkName: "update" */ "../views/Update.vue")
	},
	{
		path: "/login",
		name: "Login",
		component: () => import(/* webpackChunkName: "login" */ "../views/Login.vue")
	},
	{
		path: "/logout",
		name: "Logout",
		component: () => import(/* webpackChunkName: "logout" */ "../views/Logout.vue"),
		beforeEnter: requiresLogin
	},
	{
		path: "/verify-email/:token",
		name: "Invalid Token",
		component: () => import(/* webpackChunkName: "invalidtoken" */ "../views/InvalidToken.vue")
	},
	{
		path: "/interaction",
		redirect: "/login",
		children: [
			{
				path: "/interaction/:uid",
				redirect: to => {
					localStorage.setItem("interaction", to.params.uid);
					return { name: "Login" };
				}
			}
		]
	},
	{
		path: "/:catchAll(.*)",
		redirect: "/"
	}
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

router.afterEach((to, from) => {
	// Update the title on each page.
	nextTick(() => {
		document.title = `${meta.name} ${to.name || ""}`;
	});
});

export default router;
