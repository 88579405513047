<template>
	<div class="home">
		Home
	</div>
</template>

<script>
export default {
	name: "Home"
};
</script>
