import api from "@/api";

const state = {
	scopes: [],
	service: null,
	loggedIn: false,
	status: null,
	requires: [],
	error: null,
	csrf: document.querySelector(`meta[name="csrf-token"]`)?.getAttribute("content"),

	update: async () => {
		try {
			const { name, scopes, service, requires } = await api.getState();

			state.status = name;

			if (name === "login")
				return;

			state.requires = requires;
			state.scopes = scopes;
			state.service = service;
			state.loggedIn = name === "consent" || name === "requires";
		} catch (e) {
			state.error = e;
		}
	},

	getRoute: () => {
		if (state.status === "requires")
			return "/update";

		if (state.status === "consent")
			return "/consent";

		return "/login";
	}
};

export default { state };
