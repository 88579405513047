module.exports = (obj1, obj2) => {
	const result = {};

	for (const key of Object.keys(obj1)) {
		try {
			// If not an object direct copy.
			if (!obj1[key] || obj1[key].constructor !== Object) {
				result[key] = obj2[key] !== undefined ? obj2[key] : obj1[key];
				continue;
			}

			// Otherwise copy the first obj
			result[key] = { ...obj1[key] };

			// Then attept to copy the second
			result[key] = { ...obj1[key], ...obj2[key] };
		} catch (e) {
			continue;
		}
	}

	// Copy the keys not in common
	for (const key of Object.keys(obj2)) {
		if (result[key])
			continue;

		result[key] = obj2[key];
	}

	return result;
};
